<template>
    <modal ref="modalVerInventario" :titulo="`${tipoTitulo == 1 ? 'Usa catálogo diferente' :'Usa su propio catalogo'}`" no-aceptar no-cancelar>
        <template v-if="esPadre">
            <div class="row mx-0 px-3">
                <p class="col-12 text-general f-14">
                    Este CEDIS comparte su catálogo con:
                </p>
                <p v-for="(cedi,h) in hijos" :key="h" class="col-12 text-general f-15 f-600">
                    {{ cedi.nombre }}
                </p>
            </div>
        </template>
        <template v-else>
            <div class="row mx-0 px-3 mb-4">
                <p class="col-12 text-general f-14">
                    Este CEDIS usa el catálogo de:
                </p>
                <p class="col-6 text-general f-14 f-600">
                    {{ padre.nombre }}
                </p>
            </div>
            <div class="row mx-0 px-3">
                <p class="col-12 text-general f-14">
                    Otros CEDIS que también lo usan:
                </p>
                <p v-for="(cedi,h) in hermanos" :key="h" class="col-12 text-general f-15 f-600">
                    {{ cedi.nombre }}
                </p>
            </div>
        </template>
    </modal>
</template>

<script>
export default {
    props: {
        tipoTitulo: {
            type: Number,
            default: 1
        }
    },
    data(){
        return {
            padre: {id: null, nombre: null},
            hijos: [],
            hermanos: [],
            esPadre: false,
        }
    },
    methods: {
        toggle({padre = {id: null, nombre: null}, hijos = [], hermanos = [], esPadre = false}){
            this.padre = padre
            this.hijos = hijos
            this.hermanos = hermanos
            this.esPadre = esPadre
            this.$refs.modalVerInventario.toggle();
        }
    }
}
</script>
